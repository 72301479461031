import React from "react"
import PropTypes from "prop-types"

import { Wrapper, Icon, Title } from "./icon-list.style"

export const Size = {
  SMALL: "xs",
  LARGE: "lg",
}

IconList.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(Size)),
}

export default function IconList({
  icon,
  title,
  children,
  size = Size.LARGE,
  ...props
}) {
  return (
    <Wrapper size={size} {...props}>
      <Icon>{icon}</Icon>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  )
}

export * from "./icon-list.style"
