import styled from "styled-components"
import { rem, fluidRange } from "polished"

import { grid } from "../utils/styles"
import Card from "../components/cards/card"
import Badge from "../components/badge"
import _ContactButton from "../components/buttons/contact"

export const Content = styled.div``
export const Title = styled(Badge).attrs({ as: "h2" })``
export const Subtitle = styled.p`
  font-weight: 500;
  line-height: 1.3;
  margin: ${rem("10px")} 0 ${rem("45px")};

  ${fluidRange(
    {
      prop: "fontSize",
      fromSize: "28px",
      toSize: "42px",
    },
    "400px",
    "1000px",
  )}
`
export const Text = styled.p`
  margin-bottom: ${rem("35px")};
  font-weight: 300;
  line-height: 1.5;
`
export const ContactButton = styled(_ContactButton)``
export const ImageWrapper = styled.div`
  user-select: none;
`
export const Wrapper = styled(Card).attrs(props => ({
  shadowColor: props.shadowColor ?? "transparent",
}))`
  ${grid()}

  text-align: center;

  ${Content} {
    grid-column: main;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${Subtitle} {
    > span,
    > strong {
      color: ${props => props.theme.color.primary};
    }
  }

  ${Text} {
    max-width: 40em;
  }

  @media screen and (min-width: ${rem("1200px")}) {
    align-items: center;
    text-align: left;

    ${Content},
    ${ImageWrapper} {
      grid-row: 1 / 5;
    }

    ${Content} {
      grid-column: 3 / span 5;
      align-items: start;
    }

    ${ImageWrapper} {
      grid-column: 9 / span 4;
    }
  }
`
