import React from "react"
import PropTypes from "prop-types"
import { isFragment } from "react-is"

import {
  Card,
  Heading,
  Logos,
  Slider,
  ArrowBack,
  ArrowForward,
} from "./carousel.style"
import useResponsiveBreakpoints from "../hooks/use-responsive-breakpoints"

export const Size = {
  SMALL: "xs",
  LARGE: "lg",
  RESPONSIVE: "responsive",
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Size)),
  as: PropTypes.string,
  color: PropTypes.string,
  shadowColor: PropTypes.string,
  sliderOptions: PropTypes.object,
  headingProps: PropTypes.object,
}

export default function Carousel({
  children,
  heading,
  size,
  sliderOptions = {},
  headingProps = {},
  ...props
}) {
  children = isFragment(children) ? children.props.children : children

  const { ref, breakpoint } = useResponsiveBreakpoints(size, {
    [Size.SMALL]: 0,
    [Size.LARGE]: 500,
  })
  const count = Math.min(8, React.Children.count(children))
  const as = heading?.length > 0 ? "aside" : "div"

  return (
    <Card as={as} color="#fff" {...{ ref, breakpoint, ...props }}>
      {heading && (
        <Heading breakpoint={breakpoint} {...headingProps}>
          {heading}
        </Heading>
      )}
      <Logos>
        <Slider
          {...{
            prevArrow: <ArrowBack />,
            nextArrow: <ArrowForward />,
            arrows: false,
            infinite: true,
            slidesToShow: count,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 6,
                },
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 5,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 320,
                settings: {
                  arrows: true,
                  slidesToShow: 1,
                },
              },
            ],
            ...sliderOptions,
          }}
        >
          {children}
        </Slider>
      </Logos>
    </Card>
  )
}

export * from "./carousel.style"
