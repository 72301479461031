import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Button from "./button"

const propTypes = {
  as: PropTypes.string,
}

const ContactButton = styled(Button).attrs({
  children: "contact us",
  as: Link,
  to: "#contact",
})`
  width: ${rem("238px")};
`

ContactButton.propTypes = propTypes

export default ContactButton
