import React from "react"
import PropTypes from "prop-types"
import { useMedia } from "react-use"
import { rem } from "polished"

import {
  Wrapper,
  Content,
  Title,
  Subtitle,
  Text,
  ContactButton,
  ImageWrapper,
} from "./hero.style"

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
  titleProps: PropTypes.shape({
    as: PropTypes.string,
  }),
  contactButtonProps: PropTypes.shape({
    as: PropTypes.string,
  }),
}

export default function Hero({
  title,
  subtitle,
  text,
  image,
  titleProps,
  contactButtonProps,
  showContactButton = true,
  ...props
}) {
  const isLarge = useMedia(`(min-width: ${rem("1200px")})`)

  return (
    <Wrapper {...props}>
      <Content>
        <Title {...titleProps}>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Text>{text}</Text>
        {showContactButton && (
          <ContactButton {...contactButtonProps}></ContactButton>
        )}
      </Content>
      {isLarge && <ImageWrapper>{image}</ImageWrapper>}
    </Wrapper>
  )
}

export * from "./hero.style"
