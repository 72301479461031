import styled, { css } from "styled-components"
import { fluidRange, rem } from "polished"

import { Size } from "./icon-list"
import _Icon from "../icon"
import { List as _List, ListItem as _ListItem, Type as ListType } from "./list"

export const Icon = styled(_Icon).attrs(props => ({
  colorPrimary: props.theme.color.primary,
}))`
  ${fluidRange(
    {
      prop: "marginRight",
      fromSize: "15px",
      toSize: "26px",
    },
    "400px",
    "1000px",
  )}
`
export const Title = styled.h3`
  text-align: left;
`
export const List = styled(_List).attrs({ type: ListType.CIRCLE })`
  color: currentColor;
  align-self: flex-end;

  ${fluidRange(
    {
      prop: "paddingTop",
      fromSize: "24px",
      toSize: "41px",
    },
    "400px",
    "1000px",
  )}
`
export const ListItem = styled(_ListItem)`
  opacity: 0.5;
  text-align: left;
`
export const Wrapper = styled.article`
  color: ${props => props.theme.color.textLight};

  display: inline-grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  ${Icon},
  ${Title} {
    grid-row: 1;
  }

  ${List} {
    grid-column: 1 / 3;
  }

  ${props =>
    props.size !== Size.LARGE &&
    css`
      ${List} {
        column-count: 2;
        column-gap: ${rem("38px")};
      }
    `}

  ${props =>
    props.size === Size.LARGE &&
    css`
      ${Title},
      ${List} {
        grid-column: 2;
      }
    `}
`
