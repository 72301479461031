import styled, { css } from "styled-components"
import { rem } from "polished"
import _Slider from "react-slick"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { Size } from "./carousel"
import _Card from "./cards/card"

export const Card = styled(_Card)`
  overflow: hidden;
  padding: ${rem("30px")} ${rem("30px")};
  text-align: center;

  /* TODO: Find a way to make it work with Flexbox */
  /* ${props =>
    props.breakpoint !== Size.SMALL &&
    css`
      display: flex;
      align-items: center;
    `} */
`
export const Heading = styled.h3`
  margin: 0;
  margin-bottom: ${rem("15px")};
  flex-shrink: 0;
  font-size: ${rem("26px")};

  /* ${props =>
    props.breakpoint !== Size.SMALL &&
    css`
      margin-bottom: 0;
      margin-right: ${rem("30px")};
    `} */
`
export const Logos = styled.div`
  min-width: 0;
  min-height: 0;
`
export const Slider = styled(_Slider)`
  margin: auto;

  .slick {
    &-track {
      display: flex;
      align-items: center;
    }

    &-list {
      margin: 0 ${rem("-15px")};

      .slick-slide {
        margin: 0 ${rem("15px")};

        &[aria-hidden="true"] {
          a {
            visibility: hidden;
          }
        }
      }
    }

    &-arrow {
      z-index: 1;

      &,
      &:hover {
        color: currentColor;
      }
    }
  }
`
export const ArrowBack = styled(IoIosArrowBack)``
export const ArrowForward = styled(IoIosArrowForward)``
