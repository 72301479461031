import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Carousel from "../components/carousel"

export default function Clients(props) {
  const data = useStaticQuery(graphql`
    query {
      clients: allContentfulClient(filter: { featured: { eq: true } }) {
        nodes {
          id
          name
          website
          logo {
            localFile {
              childImageSharp {
                fixed(width: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `)

  if (data.clients.nodes.length < 1) {
    return null
  }

  const sliderOptions = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 6,
    slidesToScroll: 1,
  }

  return (
    <Carousel heading="They trust us" sliderOptions={sliderOptions} {...props}>
      {data.clients.nodes.map(({ id, logo, name, website }) => {
        const image = (
          <Img
            fixed={logo.localFile.childImageSharp.fixed}
            imgStyle={{ objectFit: "contain" }}
          />
        )

        return (
          <div key={id}>
            {website?.length > 0 ? (
              <a
                href={website}
                target="_blank"
                rel="noreferrer noopener"
                title={name}
              >
                {image}
              </a>
            ) : (
              image
            )}
          </div>
        )
      })}
    </Carousel>
  )
}
